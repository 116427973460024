<template>
    <div class="multiple-selected">
        <ul class="multiple-selected__list">
            <li
                v-for="(listItem, index) in listArray"
                :key="`msl-${uId}-${index}`"
                class="multiple-selected__item"
            >
                <v-select2
                    class="multiple-selected__select"
                    label="full_name"
                    :clearable="false"
                    :searchable="true"
                    :options="optionsArray"
                    :placeholder="isSelectParams.placeholder"
                    :value="listItem"
                    @input="(newValue) => onSelectInput(newValue, listItem, index)"
                >
                    <span slot="no-options">Ничего не найдено</span>
                </v-select2>

                <button
                    class="multiple-selected__delete"
                    type="button"
                    @click="onRemoved(listItem, index)"
                >
                    <svg width="9" height="9" viewBox="0 0 9 9">
                        <g>
                            <g>
                                <path d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"></path>
                            </g>
                        </g>
                    </svg>
                </button>
            </li>
        </ul>

        <button
            v-if="
                optionsArray.length > 0 &&
                listArray.length <= optionsArray.length
            "
            class="multiple-selected__add"
            type="button"
            @click="onAdded"
        >
            {{ isSelectParams.addLabel }}
        </button>
    </div>
</template>

<script>
    export default {
        name: 'multiple-selected-list',
        props: {
            isSelectParams: {
                type: Object,
                default: () => {
                    return {
                        placeholder: 'Выберите из списка',
                        addLabel: 'Добавить'
                    }
                }
            },

            isSelectList: {
                type: Array,
                default: () => []
            },

            isSelectOptions: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                uId: this._uid,
                listArray: JSON.parse(JSON.stringify(this.$props.isSelectList)),
                optionsArray: JSON.parse(JSON.stringify(this.$props.isSelectOptions))
            }
        },
        watch: {
            isSelectList: {
                handler: function(newVal) {
                    this.listArray = JSON.parse(JSON.stringify(newVal))
                },
                deep: true
            },

            isSelectOptions: {
                handler: function(newVal) {
                    this.optionsArray = JSON.parse(JSON.stringify(newVal))
                },
                deep: true
            }
        },
        methods: {
            onAdded() {
                this.listArray.push({})
                this.$emit('add', true)
            },

            onRemoved(selectValue, selectIndex) {
                this.listArray.splice(selectIndex, 1)

                this.$emit('remove', {
                    value: selectValue,
                    index: selectIndex
                })
            },

            onSelectInput(newValue, oldValue, selectIndex) {
                oldValue = Object.assign(oldValue, newValue)

                this.$emit('selectInput', {
                    value: newValue,
                    index: selectIndex
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/sass/_variables.sass';
    @import '@/sass/_mixins.sass';

    .multiple-selected {
        $p: &;

        &__list {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
        }

        &__select {
            flex-grow: 1;
        }

        &__delete {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border: none;
            cursor: pointer;
            height: 21px;
            width: 21px;
            margin-left: 5px;
            border-radius: 100px;
            fill: #b8b8b8;
            background-color: #e8e8e8;
            &:hover {
                fill: #000;
                background-color: #ddd;
            }
        }

        &__add {
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            width: 100%;
            border-radius: 3px;
            border: 1px dashed #e8e8e8;
            background-color: transparent;
            cursor: pointer;
        }
    }

    //.module-set__del-btn:hover {
    //    background-color: #ddd;
    //    fill: #000;
    //}
    //.module-set__del-btn:hover svg path {
    //    fill: inherit;
    //}
</style>
