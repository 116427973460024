import session from '@/api/session';

export const basicMethods = {
    props: {
        title: {
            required: false,
            type: String,
            default: 'Настройка доступа'
        },
        text: {
            required: false,
            type: String,
            default: 'Выберите отделы, должности или сотрудников, которым необходим доступ'
        },
        checkbox: {
            required: false,
            type: String,
            default: 'Для всей компании'
        },
        rubricID: {
            required: false,
            default: ''
        },
        isMainRubric: {
            required: false,
            default: true
        },
        permissionObjectID: {
            required: false,
            default: ''
        },
        permissionType: {
            required: false,
            type: String,
            default: ''
        },
        permissionTypeParent: {
            required: false,
            type: String,
            default: 'rubric'
        },
        permissionData: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            parentData: {},
            _beforeEditingCache: null,
            data: {
                searchUsers: '',
                searchStructure: '',
                isVisibleTab: true,
                leftTree: {
                    structure: [],
                    users: []
                },
                rightTree: {
                    structure: [],
                    users: []
                },
                access_all: true,
                parentRight: false,
            }
        }
    },
    // async created() {
    //     await this.loadDefaultData();
    //     await this.loadLimitedData();
    //     await this.loadRetrieveData();
    // },
    watch: {
        'data.searchStructure': {
            handler: function (val, oldVal) {
                let filter, tree, li;
                if (val !== undefined) {
                    filter = val.toUpperCase();
                    tree = $('.js-vue-department-tree');
                    li = tree.find('li');

                    if (filter.length === 0) {
                        li.removeClass('hidden').removeClass('open');
                    } else {
                        li.addClass('open');
                        tree.find('.department__row_name').each(function () {
                            if ($(this).text().toUpperCase().indexOf(filter) > -1) {
                                $(this).parents('li').addClass('open').removeClass('hidden');
                            } else {
                                $(this).closest('.open').removeClass('open').addClass('hidden');
                            }
                        });
                    }
                }

            },
            deep: true
        }
    },
    computed: {
        filteredUsers() {
            const s = this.data.searchUsers.toLowerCase();
            return this.data.leftTree.users.filter(user => user.full_name.toLowerCase().includes(s));
        }
    },
    methods: {
        async parsingPermissionData(data) {
            this.data.access_all = data.access_all;
            if (data.note && data.note.parentRight !== undefined) {
                this.data.parentRight = data.note.parentRight;
            }
            function data_collection(structure, structure_id, position) {
                structure.forEach(item => {
                    if (item.id === structure_id) {
                        item.checked = true;
                    }
                    item.position.forEach(child => {
                        if (position.includes(child.id) && item.id === structure_id) {
                            child.checked = true;
                        }
                    });
                    if (item.children.length > 0) {
                        data_collection(item.children, structure_id, position);
                    }
                })
            }

            for (let index in data.structure) {
                data_collection(this.data.leftTree.structure, Number(index), data.structure[index]);
            }
            data.users.forEach(user_id => {
                this.data.leftTree.users.forEach(item => {
                    if (item.id === user_id) {
                        item.checked = true;
                    }
                })
            });
            this.leftTreeStructBuild();
            this.usersBuild(this.data.leftTree.users);
        },
        async loadDefaultData() {
            try {
                this.data.rightTree.users = [];
                this.data.rightTree.structure = [];
                const response = await session.get('/api/v1/permission/default/');
                const leftTree = response.data;

                const dataCollection = (parent) => {
                    return parent.map((item) => ({
                        ...item,
                        checked: false,
                        active: false,
                        visible: false,
                        position: item.position.map(pos => ({
                            ...pos,
                            visible: false,
                            active: false,
                            checked: false
                        })),
                        children: dataCollection(item.children)
                    }))
                }
                this.data.leftTree = {
                    ...leftTree,
                    structure: dataCollection(leftTree.structure),
                };
            } catch (err) {
                console.error(err)
            }
        },
        async loadLimitedData() {
            if (this.rubricID) {
                try {
                    const params = {
                        permission_type: this.permissionType,
                        permission_object_id: this.permissionObjectID
                    };
                    const { data } = await session.get('/api/v1/permission/retrieve_vue/', { params });
                    if (Object.keys(data.structure).length !== 0) {
                        this.structureBuildWithParent(this.data.leftTree.structure, data.structure);
                    } else if (Object.keys(data.structure).length === 0 && data.access_all === false) {
                        this.data.rightTree.structure = [];
                    }
                    this.data.access_all = data.access_all;
                    this.data.parentRight = data.note.parentRight;
                    if (data.users.length > 0) {
                        this.data.rightTree.users = this.data.leftTree.users.filter(
                            user => data.users.some(userId => userId === user.id)
                        )
                    } else if (data.users.length === 0 && data.access_all === false) {
                        this.data.rightTree.users = []
                    }
                    this.parentData = Object.assign({}, this.data);
                    this.parentData.access_all = data.access_all;
                } catch (err) {
                    console.error(err)
                }
            }
        },
        async initializePermissionData() {
            if (this.permissionData) {
                await this.parsingPermissionData(this.permissionData);
            }
        },
        async loadRetrieveData() {
            if (this.permissionType && this.permissionObjectID && this.isMainRubric) {
                try {
                    const params = {
                        permission_type: this.permissionType,
                        permission_object_id: this.permissionObjectID
                    };
                    const { data } = await session.get('/api/v1/permission/retrieve_vue/', { params });
                    await this.parsingPermissionData(data);
                } catch (err) {
                    console.error(err)
                }
            }
        },
        structureBuildWithParent(leftStructTree, structure) {
            const structureList = [];

            function child_data_collection(parent, children, isStruct) {
                if (children) {
                    children.forEach(function(item) {
                        const child = {
                            id: item.id,
                            name: item.name,
                            checked: item.checked,
                            active: false,
                        };
                        if (item.position !== undefined) {
                            child.children = [];
                            child.position = [];
                        }
                        if (structure[item.id] && isStruct) {
                            if (parent && isStruct) {
                                parent.children.push(child);
                            } else {
                                structureList.push(child);
                            }
                            child_data_collection(child, item.position, false);
                            child_data_collection(child, item.children, true);
                        } else if (parent && structure[parent.id] && structure[parent.id].includes(item.id) && !isStruct) {
                            if (parent) {
                                parent.position.push(child);
                            }
                            child_data_collection(child, item.position, false);
                            child_data_collection(child, item.children, true);
                        } else {
                            child_data_collection(parent, item.position, false);
                            child_data_collection(parent, item.children, true);
                        }
                    })
                }
            }

            leftStructTree.forEach(function(item) {
                if (structure[item.id]) {
                    const obj = {
                        id: item.id,
                        name: item.name,
                        checked: item.checked,
                        active: false,
                        children: [],
                        position: [],
                    };
                    structureList.push(obj);
                    child_data_collection(obj, item.position, false);
                    child_data_collection(obj, item.children, true);
                } else {
                    child_data_collection(null, item.position, false);
                    child_data_collection(null, item.children, true);
                }
            });
            this.data.rightTree.structure = structureList;
        },
        dataPreparation() {
            const data = {
                structure: {},
                users: [],
                access_all: this.data.access_all,
                note: {
                    parentRight: this.data.parentRight
                },
                access: {
                    users: this.data.access.users,
                    all_users: this.data.access.all_users
                }
            };

            this.data.rightTree.users.forEach(function(item) {
                data.users.push(item.id);
            });

            function data_collection(structure) {
                structure.forEach(function (item) {
                    data.structure[item.id] = [];
                    item.position.forEach(position => {
                        data.structure[item.id].push(position.id);
                    });
                    data_collection(item.children);
                });
            }
            data_collection(this.data.rightTree.structure);
            return data;
        },
        copyParentPermission() {
            let parentRight = this.data.parentRight;
            if (this.rubricID && parentRight) {
                this._beforeEditingCache = JSON.parse(JSON.stringify(this.data));
                let data = {access_all: false};
                if (this.parentData) {
                    data = Object.assign({}, this.parentData);
                }
                if (!data.access_all) {
                    function data_collection(structure) {
                        structure.forEach(item => {
                            item.checked = true;
                            item.position.forEach(child => {
                                child.checked = true;
                            });
                            if (item.children.length > 0) {
                                data_collection(item.children);
                            }
                        })
                    }

                    data_collection(this.data.leftTree.structure);
                    this.data.leftTree.users.forEach(user => {
                        user.checked = true;
                    });
                    this.structureBuild(this.data.leftTree.structure);
                    this.usersBuild(this.data.leftTree.users);
                }
            } else if (this.rubricID && !parentRight && this._beforeEditingCache) {
                this.data = JSON.parse(JSON.stringify(this._beforeEditingCache));
                this.data.parentRight = false;
                this.data.access_all = false;
            } else if (this.rubricID && !parentRight) {
                this.data.parentRight = false;
                this.data.access_all = false;
            } else {
                this.data.parentRight = false;
                this.data.access_all = false;
            }
        },
        clickStructure(item_id, checked) {
            function data_collection(structure) {
                structure.forEach(function (item) {
                    item.checked = checked;
                    item.position.forEach(function (child) {
                        child.checked = checked;
                    });
                    data_collection(item.children);
                });
            }
            function find_structure(structure, item_id) {
                structure.forEach(function (item) {
                    if (item.id === item_id) {
                        item.checked = checked;
                        item.position.forEach(function (child) {
                            child.checked = checked;
                        });
                        data_collection(item.children);
                    } else {
                        find_structure(item.children, item_id)
                    }
                });
            }
            find_structure(this.data.leftTree.structure, item_id);
            this.leftTreeStructBuild();
        },
        clickPosition(position_id, checked) {
            function data_collection(structure) {
                structure.forEach(function (item) {
                    item.position.forEach(position => {
                        if (position.id === position_id) {
                            position.checked = checked;
                            item.checked = checked;
                            item.position.forEach(function (child) {
                                if (child.checked) {
                                    item.checked = true;
                                }
                            });
                            item.children.forEach(function (child) {
                                if (child.checked) {
                                    item.checked = true;
                                }
                            });
                        }
                    });
                    data_collection(item.children);
                });
            }
            data_collection(this.data.leftTree.structure);
            this.leftTreeStructBuild();
        },
        leftTreeStructBuild() {
            this.structureBuild(this.data.leftTree.structure);
        },
        leftTreeUsersBuild(item) {
            item.checked = !item.checked;
            this.usersBuild(this.data.leftTree.users);
        },
        rightTreeUsersBuild(item) {
            item.checked = !item.checked;
            let event = new Event("click");
            document.querySelector('input[data-id="original-worker-' + item.id + '"]').dispatchEvent(event);
            document.querySelector('input[data-id="original-worker-' + item.id + '"]').checked = false;
            this.usersBuild(this.data.rightTree.users);
        },
        structureBuild(leftStructTree) {
            let structureList = [];

            function child_data_collection(parent, children, is_struct) {
                if (children) {
                    children.forEach(function (item) {
                        let
                            child = Object({
                                id: item.id,
                                name: item.name,
                                checked: item.checked,
                                active: false,
                            });
                        if (item.position !== undefined) {
                            child.children = [];
                            child.position = [];
                        }
                        if (item.checked) {
                            if (parent && is_struct) {
                                parent.children.push(child);
                            } else if (parent) {
                                parent.position.push(child);
                            } else {
                                structureList.push(child);
                            }
                            child_data_collection(child, item.position, false);
                            child_data_collection(child, item.children, true);
                        } else {
                            child_data_collection(parent, item.position, false);
                            child_data_collection(parent, item.children, true);
                        }
                    })
                }
            }

            leftStructTree.forEach(function (item) {
                let
                    obj = Object({
                        id: item.id,
                        name: item.name,
                        checked: item.checked,
                        active: false,
                        children: [],
                        position: [],
                    });

                if (item.checked) {
                    structureList.push(obj);
                    child_data_collection(obj, item.position, false);
                    child_data_collection(obj, item.children, true);
                } else {
                    child_data_collection(null, item.position, false);
                    child_data_collection(null, item.children, false);
                }
            });

            this.data.rightTree.structure = structureList;
        },
        usersBuild(Users) {
            let usersList = [];

            Users.forEach(function (item) {
                let
                    obj = Object({
                        id: item.id,
                        full_name: item.full_name,
                        checked: item.checked
                    });

                if (item.checked) {
                    usersList.push(obj);
                }
            });

            this.data.rightTree.users = usersList;
        }
    }
}
