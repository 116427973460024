<template>
    <div>
        <div v-if="isLoaded" class="modal-dialog modal-dialog--access block block-mode-loading-refresh">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-block-option close-modal" @click="$modal.hide('ModalPermissionUpdate')">×</button>
                </div>

                <div class="modal-staff__tab-head">
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 1}" @click="currentTab = 1">Администрирование</div>
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 2}" @click="currentTab = 2">Редактирование</div>
                    <div class="modal-staff__tab-name" :class="{'modal-staff__tab-name_active':currentTab === 3}" @click="currentTab = 3">Доступ</div>
                </div>

                <div class="block-content content modal-staff">
                    <!--Администрирование-->
                    <div v-if="currentTab === 1" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div v-if="
                                    usersArray.initial.length > 0 ||
                                    usersArray.access.length > 0
                                "
                            >
                                <div class="module-set__subtitle">
                                    Сотрудники, которые могут администрировать материал
                                    <sup
                                        class="tooltip-mark"
                                        tabindex="0"
                                        v-tooltip="{
                                            content: 'У пользователя есть возможность создавать, изменять и удалять отдельный материал, а так же настраивать доступы остальным пользователям.'
                                        }"
                                    >
                                        ?
                                    </sup>
                                </div>

                                <div class="form-group row col-12">
                                    <label
                                        class="modal-staff__css-control css-control"
                                        for="id_admin_all_users"
                                    >
                                        <input
                                            class="css-control-input"
                                            id="id_admin_all_users"
                                            type="checkbox"
                                            name="admin_all_users"
                                            v-model="adminAllUsers"
                                        >
                                        <span class="css-control-indicator"></span>
                                        Все сотрудники
                                    </label>
                                </div>

                                <div class="module-set__list">
                                    <multiple-selected-list
                                        :is-select-list="selectedAdminUsers"
                                        :is-select-options="usersArray.access"
                                        @remove="onRemoveSelectedAdmin"
                                        @selectInput="onInputSelectedAdmin"
                                    />
                                </div>
                            </div>

                            <p
                                v-else-if="usersArray.initial.length === 0"
                                class="module-set__note"
                            >
                                Необходимо до конца настроить общий <button type="button" @click="currentTab = 3">доступ</button> к материалу.
                            </p>
                        </div>
                    </div>

                    <!--Редактирование-->
                    <div v-if="currentTab === 2" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div v-if="usersEditorList.length > 0">
                                <div class="module-set__subtitle">
                                    Сотрудники, которые могут редактировать материал
                                    <sup
                                        class="tooltip-mark"
                                        tabindex="0"
                                        v-tooltip="{
                                            content: 'У пользователя есть возможность как создавать дочерние материалы, так и редактировать отдельный материал.'
                                        }"
                                    >
                                        ?
                                    </sup>
                                </div>

                                <div class="module-set__list">
                                    <multiple-selected-list
                                        :is-select-list="editorUsersArrays.selected"
                                        :is-select-options="editorUsersArrays.excluded"
                                        :is-select-params="{ addLabel: 'Вернуть пользователя' }"
                                        @remove="onRemoveSelectedEditor"
                                        @selectInput="onInputSelectedEditor"
                                    />
                                </div>
                            </div>

<!--                            <p-->
<!--                                v-else-if="usersArray.initial.length === 0"-->
<!--                                class="module-set__note"-->
<!--                            >-->
<!--                                Необходимо до конца настроить общий <button type="button" @click="currentTab = 3">доступ</button> к материалу.-->
<!--                            </p>-->

                            <p
                                v-else-if="usersEditorList.length === 0"
                                class="module-set__note"
                            >
                                Пока нету пользователей с ролью «Редактор».
                            </p>
                        </div>
                    </div>

                    <div v-if="currentTab === 3" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div class="modal-header modal-header_no-border">
                                <h3 class="block-title">{{ title }}</h3>

                                <label class="material-toggle">
                                    <input
                                        v-if="rubricID"
                                        type="checkbox"
                                        class="material-toggle__input"
                                        checked="checked"
                                        v-model="form.parentRight"
                                        @change="copyParentPermission"
                                    >

                                    <input
                                        v-else
                                        type="checkbox"
                                        class="material-toggle__input"
                                        checked="checked"
                                        v-model="form.access_all"
                                    >
                                    <span class="material-toggle__icon"></span>
                                    <span class="material-toggle__text">{{ checkbox }}</span>
                                </label>
                            </div>

                            <div class="workers__desc">
                                {{ text }}
                            </div>

                            <div
                                class="row"
                                v-bind:class="{ area_lock: form.access_all || form.parentRight }"
                            >
                                <div class="col-lg-6 workers">
                                    <div class="workers__col">
                                        <div class="workers__head">
                                            <ul class="material-header__nav nav">
                                                <li class="nav-item">
                                                    <a
                                                        id="vue-access-department-tab"
                                                        v-bind:class="{ active: form.isVisibleTab }"
                                                        @click="form.isVisibleTab=true"
                                                    >Общее</a>
                                                </li>

                                                <li v-if="showUsersTab" class="nav-item">
                                                    <a
                                                        id="vue-access-workers-tab"
                                                        v-bind:class="{ active: !form.isVisibleTab }"
                                                        @click="form.isVisibleTab = false"
                                                    >Сотрудники</a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="workers__body">
                                            <div class="tab-content">
                                                <div
                                                    class="tab-pane fade"
                                                    id="vue-access-department"
                                                    v-bind:class="{ 'active show': form.isVisibleTab }"
                                                >
                                                    <div class="workers__search form-group">
                                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                        </svg>

                                                        <input
                                                            type="text"
                                                            class="form-control round"
                                                            placeholder="Поиск"
                                                            v-model="form.searchStructure"
                                                        >
                                                    </div>

                                                    <div class="workers__tree">
                                                        <ul class="workers__department department js-vue-department-tree">
                                                            <permission-tree-structure
                                                                v-for="(item) in form.leftTree.structure"
                                                                :key="item.id"
                                                                :item="item"
                                                                :leftTree="true"
                                                                @buildStructTree="clickStructure"
                                                                @buildPositionTree="clickPosition"
                                                            ></permission-tree-structure>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div
                                                    class="tab-pane fade"
                                                    id="vue-access-workers"
                                                    v-bind:class="{'active show': !form.isVisibleTab}"
                                                >
                                                    <div class="workers__search form-group">
                                                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                        </svg>

                                                        <input
                                                            type="text"
                                                            placeholder="Поиск"
                                                            class="form-control round"
                                                            v-model="form.searchUsers"
                                                        >
                                                    </div>

                                                    <div class="workers__tree">
                                                        <ul class="workers__department department">
                                                            <li
                                                                v-for="(item) in filteredUsers"
                                                                class="department__item open"
                                                            >
                                                                <div class="department__row">
                                                                    <span class="department__row_name">
                                                                        {{ item.full_name }}
                                                                    </span>

                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            class="access_departament"
                                                                            :data-id="'original-worker-' + item.id"
                                                                            :checked="item.checked"
                                                                            @click="leftTreeUsersBuild(item)"
                                                                        >

                                                                        <div class="department__row_button">
                                                                            <i
                                                                                v-if="item.checked"
                                                                                class="fas fa-check"
                                                                            ></i>
                                                                            <i v-else class="fas fa-plus"></i>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 workers workers--second">
                                    <form class="workers__col">
                                        <div class="workers__head">
                                            <ul class="material-header__nav nav" role="tablist">
                                                <li class="nav-item">
                                                    <a
                                                        id="vue-sel-access-department-tab"
                                                        v-bind:class="{active: form.isVisibleTab}"
                                                        @click="form.isVisibleTab=true"
                                                    >
                                                        Общее
                                                        <span class="nav-item__numb">
                                                            {{ form.rightTree.structure.length }}
                                                        </span>
                                                    </a>
                                                </li>

                                                <li class="nav-item" v-if="showUsersTab">
                                                    <a
                                                        id="vue-sel-access-workers-tab"
                                                        v-bind:class="{active: !form.isVisibleTab}"
                                                        @click="form.isVisibleTab=false"
                                                    >
                                                        Сотрудники
                                                        <span class="nav-item__numb">
                                                            {{ form.rightTree.users.length }}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="workers__body">
                                            <div class="tab-content">
                                                <div
                                                    class="tab-pane fade"
                                                    id="vue-sel-access-department"
                                                    role="tabpanel"
                                                    aria-labelledby="material-tab"
                                                    v-bind:class="{'active show': form.isVisibleTab}"
                                                >
                                                    <div class="workers__tree">
                                                        <ul class="workers__department department">
                                                            <permission-tree-structure
                                                                v-for="(item) in form.rightTree.structure"
                                                                :key="item.id"
                                                                :item="item"
                                                                @buildStructTree="clickStructure"
                                                                @buildPositionTree="clickPosition"
                                                            ></permission-tree-structure>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div
                                                    class="tab-pane fade"
                                                    id="vue-sel-access-workers"
                                                    role="tabpanel"
                                                    aria-labelledby="access-tab"
                                                    v-bind:class="{'active show': !form.isVisibleTab}"
                                                >
                                                    <div class="workers__list">
                                                        <ul class="workers__department department">
                                                            <li
                                                                v-for="(item) in form.rightTree.users"
                                                                class="department__item open"
                                                            >
                                                                <div class="department__row">
                                                                    <span class="department__row_name">
                                                                        {{ item.full_name }}
                                                                    </span>

                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            class="access_departament"
                                                                            :data-id="'worker-' + item.id"
                                                                            :checked="item.checked"
                                                                            @click.prevent="rightTreeUsersBuild(item)"
                                                                        >

                                                                        <div class="department__row_button">
                                                                            <i
                                                                                v-if="item.checked"
                                                                                class="fas fa-check"
                                                                            ></i>

                                                                            <i
                                                                                v-else
                                                                                class="fas fa-plus"
                                                                            ></i>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="module-set__footer d-flex justify-content-center">
                        <button
                            type="button"
                            class="v-btn"
                            @click="onSubmits"
                        >Сохранить</button>

                        <button
                            type="button"
                            class="v-btn v-btn--transparent"
                            @click="$modal.hide('ModalPermissionUpdate')"
                        >Отменить</button>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner v-else/>
    </div>
</template>

<script>
    import session from '@/api/session'
    import PermissionTreeStructure from './PermissionTreeStructure'
    import { basicMethods } from './basic_methods'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import MultipleSelectedList from '@/components/multiple-selected-list/MultipleSelectedList'
    import { mapState } from 'vuex'

    export default {
        name: 'ModalPermissionUpdate',
        components: {
            LoadingSpinner,
            PermissionTreeStructure,
            MultipleSelectedList
        },
        mixins: [
            basicMethods
        ],
        props: {
            showUsersTab: {
                required: false,
                default: true
            },
            afterUpdate: {
                type: Function,
                required: false
            }
        },
        data() {
            return {
                form: {},
                usersArray: {
                    access: [],
                    initial: []
                },
                selectedAdminUsers: [],
                editorUsersArrays: {
                    selected: [],
                    excluded: []
                },
                currentTab: 1,
                isLoaded: false,
                adminAllUsers: false,
                editorAllUsers: false
            }
        },
        computed: {
            ...mapState('users', ['usersEditorList'])
        },
        async created() {
            await this.loadDefaultData()
            await this.loadLimitedData()
            await this.loadRetrieveData()

            if (
                this.rubricID && !this.isMainRubric &&
                !this.data.access_all && this.data.parentRight
            ) {
                this.copyParentPermission()
            }

            this.form = this.data
            this.leftTreeUsers = this.data.leftTree.users

            this.usersArray.access = this.data.access_all ? JSON.parse(JSON.stringify(this.leftTreeUsers)) : this.data.rightTree.users
            this.usersArray.initial = JSON.parse(JSON.stringify(this.usersArray.access))

            // Юзеры с правами «Редактора»
            this.usersArray.access.forEach((user) => {
                const editorUser = JSON.parse(JSON.stringify(this.usersEditorList)).find((editorItem) => {
                    const isExcludeMaterial = editorItem.excludes.length > 0 ? editorItem.excludes.find((id) => id !== parseInt(this.permissionObjectID)) : false

                    if (editorItem.user === user.id) {
                        return Object.assign(
                            editorItem,
                            {
                                isExclude: (isExcludeMaterial === undefined) // проверим, исключен ли материал из редактирования
                            }
                        )
                    }
                })

                if (editorUser !== undefined) {
                    const userParams = Object.assign(user, {
                        excludes: editorUser.excludes
                    })

                    if (editorUser.isExclude === false) {
                        this.editorUsersArrays.selected.push(userParams)
                    } else {
                        this.editorUsersArrays.excluded.push(userParams)
                    }
                }
            })

            await session.get(`/api/v1/material/${this.permissionObjectID}/`)
                .then((res) => {
                    const data = res.data
                    const access = {
                        users: data.access ? data.access.users : [],
                        all_users: data.access ? data.access.all_users : false
                    }

                    if (access.users.length > 0) {
                        access.users.forEach((userId) => {
                            this.selectedAdminUsers.push({
                                id: userId,
                                uuid: this.$uuid.v1(),
                                users: this.usersArray.initial
                            })
                        })
                    }

                    this.form.access = {}
                    this.form.access.users = access.users
                    this.form.access.all_users = access.all_users

                    this.adminAllUsers = this.form.access.all_users
                })
                .catch((error) => {
                    console.error(error)
                })

            this.isLoaded = true
        },
        watch: {
            adminAllUsers: {
                handler: function(newVal) {
                    if (newVal === true) {
                        this.editorAllUsers = false
                        this.form.access.editor_all_users = false
                    }

                    this.form.access.all_users = newVal
                },
                deep: true
            },

            editorAllUsers: {
                handler: function(newVal) {
                    if (newVal === true) {
                        this.adminAllUsers = false
                        this.form.access.all_users = false
                    }

                    this.form.access.editor_all_users = newVal
                },
                deep: true
            },

            'form.rightTree.users': {
                handler: function(newVal) {
                    this.usersArray.access = newVal
                    this.usersArray.initial = newVal
                },
                deep: true
            },

            'form.access_all': {
                handler: function(newVal) {
                    if (newVal === true) {
                        this.usersArray.initial = this.form.leftTree.users
                        this.usersArray.access = JSON.parse(JSON.stringify(this.form.leftTree.users))
                    } else {
                        if (this.form.rightTree.users.length > 0) {
                            this.usersArray.initial = this.form.rightTree.users
                            this.usersArray.access = JSON.parse(JSON.stringify(this.form.rightTree.users))
                        } else {
                            this.usersArray.initial = []
                            this.usersArray.access = []
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            onSubmits(e) {
                e.preventDefault()

                const idMaterial = parseInt(this.permissionObjectID)

                // Пользователи: Администрирование
                this.selectedAdminUsers.forEach(user => {
                    if (user.id) {
                        this.form.access.users.push(user.id)
                    }
                })

                // Пользователи: Редактирование
                // 1). Исключаем статью и тем самым ограничим юзера редактированием
                if (this.editorUsersArrays.excluded.length > 0) {
                    const editorUsersExcluded = JSON.parse(JSON.stringify(this.editorUsersArrays.excluded)).map((user) => {
                        return {
                            user: user.id,
                            excludes: user.excludes.concat([idMaterial])
                        }
                    })

                    editorUsersExcluded.forEach((user) => {
                        this.$store.dispatch('users/excludesUserEditor', user)
                    })
                }

                // 2). Наоборот, для юзеров уберём статью из исключения
                if (this.editorUsersArrays.selected.length > 0) {
                    const editorUsersExcluded = JSON.parse(JSON.stringify(this.editorUsersArrays.selected)).map((user) => {
                        const excludesArray = user.excludes.filter((id) => id !== idMaterial)
                        return {
                            user: user.id,
                            excludes: excludesArray
                        }
                    })

                    editorUsersExcluded.forEach((user) => {
                        this.$store.dispatch('users/excludesUserEditor', user)
                    })
                }
                // /Пользователи: Редактирование

                const data = this.dataPreparation()
                let url = '/api/v1/permission/'

                url += '?permission_type=' + this.permissionType
                url += '&permission_object_id=' + this.permissionObjectID

                session
                    .post(url, data)
                    .then(response => {
                        data.info_text = response.data.text

                        this.$modal.hide('ModalPermissionUpdate', { data })

                        if (this.afterUpdate) {
                            this.afterUpdate(data)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            },

            async onInputSelectedAdmin(params) {
                const userIndex = params.index
                const userValue = params.value

                if (this.selectedAdminUsers[userIndex]) {
                    this.selectedAdminUsers[userIndex] = userValue
                } else {
                    this.selectedAdminUsers.push(userValue)
                }

                this.methodChangeArrayAccess()
            },

            async onRemoveSelectedAdmin(params) {
                this.selectedAdminUsers.splice(params.index, 1)
                this.methodChangeArrayAccess()
            },

            async onInputSelectedEditor(params) {
                const userIndex = params.index
                const userValue = params.value

                if (userValue.short_name) {
                    this.editorUsersArrays.selected.push(userValue)

                    if (this.editorUsersArrays.excluded.length > 1) {
                      this.editorUsersArrays.excluded.splice(userIndex, 1)
                    } else {
                      this.editorUsersArrays.excluded = []
                    }
                }
            },

            async onRemoveSelectedEditor(params) {
                const userIndex = params.index
                const userValue = params.value

                console.log('params: ', params)
                console.log('userValue:', userValue.short_name)
                if (userValue.short_name) {
                    this.editorUsersArrays.excluded.push(userValue)

                    if (this.editorUsersArrays.selected.length > 1) {
                        this.editorUsersArrays.selected.splice(userIndex, 1)
                    } else {
                        this.editorUsersArrays.selected = []
                    }
                }
            },

            methodChangeArrayAccess() {
                const selectedArray = [].concat(this.editorUsersArrays, this.selectedAdminUsers)

                this.usersArray.access = JSON.parse(JSON.stringify(this.usersArray.initial))
                this.usersArray.access = this.usersArray.access.filter((user) => {
                    const findId = selectedArray.findIndex((selectedUser) => selectedUser.id === user.id)

                    return findId === -1
                })
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    @import "#sass/settings";

    .v--modal-overlay[data-modal="ModalPermissionUpdate"] {
        z-index: 999999 !important;
        .v--modal-box {
            max-width: 740px;
            overflow: visible !important;
        }

        .modal {
            &-header {
                margin-bottom: 25px;
            }

            &-dialog {
                & > .modal-content {
                    padding: 30px 0px;
                }
            }
        }

        .close-modal {
            top: 0px;
            right: 0px;
        }
    }

    .modal {
        overflow-y: auto;
    }

    .module-set {
        &__subtitle {
            margin: 0 0 15px;
        }

        &__save {
            &-btn {
                margin-right: 16px;
            }
        }

        &__note {
            color: $text-darkgray;
            button {
                color: $link-main;
                text-decoration: underline;
                border: 0;
                padding: 0;
                cursor: pointer;
                background-color: transparent;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .tooltip {
        z-index: 1000000 !important;
    }

    #vue-sel-access-workers {
        height: 260px !important;
        .workers__list {
            height: 100% !important;
        }
    }

    .show-permission {
        display: flex;
        background: rgba(0, 0, 0, 0.2);
    }

    .area_lock {
        opacity: 0.5;
        pointer-events: none;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350;
    }

    .modal-dialog--access .workers__col{
        height: 100%;
    }

    .modal-dialog--access.block {
        margin: 0 auto;
    }

    @media (max-width: 660px) {.workers__body
        .workers--second {
            margin-top: 20px;
        }
    }

    .workers__tree {
        height: auto;
        min-height: 250px;
    }

    .workers__col {
        height: 100% !important;
    }

</style>
